<template>
    <div v-if="Object.keys(visibleSources).length">
        <h2 class="h4">
            <nuxt-link :to="{ name: 'sources' }">
                <Icon v="books" />
                <T>sources.headerLong</T><T>quotation.colon</T>
            </nuxt-link>
        </h2>

        <section v-for="(optionSources, option) in visibleSources" :key="option">
            <h3 class="h5">
                <nuxt-link :to="`/${option}`">
                    <span v-if="option.includes('&')">
                        <T>pronouns.alt.header</T>
                        <small>({{ option.replace(/&/g, glue) }})</small>
                    </span>
                    <span v-else-if="option && getPronoun(pronouns, option)">
                        {{ getPronoun(pronouns, option)?.description }}
                        <small>({{ getPronoun(pronouns, option)?.name(glue) }})</small>
                    </span>
                </nuxt-link>
            </h3>

            <SourceList :pronoun="pronoun" :sources="optionSources" />
        </section>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { pronouns } from '../src/data.ts';
import { getPronoun } from '../src/buildPronoun.ts';
import type { PropType } from 'vue';
import type { Source } from '../src/classes.ts';

export default defineComponent({
    props: {
        pronoun: { },
        sources: { required: true, type: Object as PropType<Record<string, Source[]>> },
    },
    data() {
        return {
            pronouns,
            getPronoun,
            glue: ` ${this.$t('pronouns.or')} `,
        };
    },
    computed: {
        visibleSources(): Record<string, Source[]> {
            return Object.fromEntries(Object.entries(this.sources).filter(([_, sources]) => sources));
        },
    },
});
</script>
